import { FC } from "react";
import {
  Box, List, ListItem
} from "@mui/material";
import { ChatPreview } from "../chat-preview";
import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import { getChatAction } from "../../../store/slices/chatSlices/chatsListSlice";
import { ChatListHeader } from "../chat-list__header";
import { IActiveChatState } from "../../../models/states/IChatsState";
import { addChatStoredChatsAction, storedChatsSelector } from "../../../store/slices/chatSlices/storedChatsSlice";
import { activeChatSelector, changeActiveChatAction } from "../../../store/slices/chatSlices/activeChatSlice";
import {
  messageSliceChangeEditTextAction,
  messageSliceChangeIsEditAction
} from "../../../store/slices/chatSlices/messageSlice";

type TProps = {
    list?: any,
    chat?: IActiveChatState | null,
    menuList?: any,
}

export const ChatList: FC<TProps> = ({ list, chat, menuList }) => {
  const dispatch = useDispatch();
  const storedChats = useSelector(storedChatsSelector);
  const activeChat = useSelector(activeChatSelector);

  const openChat = (id: string, name: string, unread: number) => {
    if (activeChat.id !== id) {
      if (!storedChats[(id as keyof IActiveChatState)]) {
        dispatch(addChatStoredChatsAction(activeChat));
        dispatch(getChatAction({ id, name, unread }));
      }
      else {
        dispatch(addChatStoredChatsAction(activeChat));
        dispatch(changeActiveChatAction(storedChats[id]));
      }
      dispatch(messageSliceChangeEditTextAction(''));
      dispatch(messageSliceChangeIsEditAction(false));
    }
  };

  return (
    <Box className="chat-list__wrap">
      <ChatListHeader itemsForMenu={menuList} />
      <List className="chat-list styled-scroll">
        {list !== null && list.map((el: any) => (
          <ListItem
            onClick={() => openChat(el.id, el.name, el.unread)}
            className={chat && chat.id === el.id ? 'chat-item active' : 'chat-item'}
            key={el.id}
          >
            <ChatPreview item={el} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
