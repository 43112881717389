import {IError, IStore} from "../models";
import {
  addTimeDiffTokenAction,
  changeIsRefreshingAction, tokensRefreshSelector, writeTokensAction
} from "../store/slices/tokensSlice";
import { fetchRefreshToken } from "../services/RefreshService";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import {countDiffTime} from "../helpers";

export const makeRefresh = async (store: IStore, callback?: () => any) => {
  try {
    store.dispatch(changeIsRefreshingAction(true));
    const refToken = tokensRefreshSelector(store.getState());
    const newTokens = await fetchRefreshToken(refToken);

    if (!(newTokens as object).hasOwnProperty('errorCode')) {
      store.dispatch(writeTokensAction(newTokens));
      store.dispatch(changeIsRefreshingAction(false));

      if (callback) {
        return await callback();
      }
    }
    else {
      throw newTokens
    }
  }
  catch (e:any) {
    if (e.messages.OperationTime) {
      let diffTime = countDiffTime(e.messages.OperationTime[0]);
      store.dispatch(addTimeDiffTokenAction(diffTime))
    }
    store.dispatch(changeIsRefreshingAction(false));
    return Promise.reject(e);
  }
};
