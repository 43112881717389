import { IStore } from "../../models";
import { AxiosError } from "axios";
import { logoutAction, tokensIsRefreshingSelector, } from "../../store/slices/tokensSlice";
import { makeRefresh } from "../makeRefresh";

const handleRefreshError = async (axios: any, error: AxiosError, store: IStore) => {
  return await new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      const updatedIsRefreshing = tokensIsRefreshingSelector(store.getState());
      if (!updatedIsRefreshing) {
        resolve(makeRefresh(store, axios.request.bind(error.config)));
        clearInterval(interval);
      }
    }, 100);
  }).then(res => res);
};

export const handleError = async (axios: any, error: AxiosError, store: IStore) => {
  if (error && error.response) {
    if (error.response.status === 401) {
      store.dispatch(logoutAction());

      return Promise.reject(error);
    }
    //@ts-ignore
    else if (error.response.data && error.response.data.errorCode === 'RefreshRequired') {
      return await handleRefreshError(axios, error, store);
    }
    else {
      return Promise.reject(error);
    }
  }
};
