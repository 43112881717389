import { AxiosError, AxiosResponse } from "axios";
import { IError } from "../models";

const errorsCodes =[
  'None',
  'InternalServerError',
  'ActionFailed',
  'NotFound',
  'BadRequest',
  'Conflict',
  'ActionCanceled',
  'Unauthorized',
  'Forbidden',
  'TokenNotValid',
  'RefreshRequired',
  'ERR_NETWORK',
  'Conflict',
];

export const transformError = (error: AxiosError): IError => {
  let errorObject: IError | null = null;

  if (error.response) {
    const { data, status } = (error.response as AxiosResponse);
    errorObject = {
      status: status,
      errorCode: errorsCodes.indexOf(data.errorCode),
      messages: data.messages
    };
  }
  else {
    const axError: AxiosError = (error.toJSON() as AxiosError);

    errorObject = {
      status: axError.status ? axError.status : null,
      errorCode: errorsCodes.indexOf(axError.name),
      messages: { Axios: axError.message }
    };
  }

  return errorObject as IError;
};

