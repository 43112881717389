import { createAction, createSlice } from "@reduxjs/toolkit";
import {
  IAction, IStoreState, ITokenState
} from "../../models";

const initialState: ITokenState = {
  refreshToken: '',
  accessToken: '',
  refresh_valid: '',
  timeDiff: 0,
  expire: 0,
  logTime: 0,
  email_confirmed: false,
  isRefreshing: false,
};

export const tokensSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    addAccessTokenAction(state: ITokenState, action: IAction<string>) {
      state.accessToken = action.payload;
    },
    addRefreshTokenAction(state: ITokenState, action: IAction<string>) {
      state.refreshToken = action.payload;
    },
    addTimeDiffTokenAction(state: ITokenState, action: IAction<number>) {
      state.timeDiff = action.payload;
    },
    addExpireAction(state: ITokenState, action: IAction<number>) {
      state.expire = action.payload;
    },
    addLogTimeAction(state: ITokenState, action: IAction<number>) {
      state.logTime = action.payload;
    },
    addRefreshValidAction(state: ITokenState, action: IAction<string>) {
      state.refresh_valid = action.payload;
    },
    changeIsRefreshingAction(state: ITokenState, action: IAction<boolean>) {
      console.log(action.payload);
      state.isRefreshing = action.payload;
    },
    updateEmailConfirmAction(state: ITokenState, action: IAction<boolean>) {
      state.email_confirmed = action.payload;
    },
    clearTokensAction(state: ITokenState) {
      state.expire = 0;
      state.accessToken = '';
      state.refreshToken = '';
      state.email_confirmed = false;
    }

  }

});

export const tokensReducer = tokensSlice.reducer;

// ACTIONS
export const WRITE_TOKENS: string = 'tokensSlice/writeTokensAction';
export const writeTokensAction = createAction(WRITE_TOKENS, payload => ({ payload }));

export const UPDATE_TOKENS: string = 'tokensSlice/updateTokens';
export const updateTokensAction = createAction(UPDATE_TOKENS);

export const UPDATE_CONFIRM: string = 'tokensSlice/updateConfirm';
export const updateConfirmAction = createAction(UPDATE_CONFIRM, payload => ({ payload }));

export const LOGOUT: string = 'tokensSlice/logout';
export const logoutAction = createAction(LOGOUT,);

export const {
  addAccessTokenAction,
  addRefreshTokenAction,
  addExpireAction,
  updateEmailConfirmAction,
  clearTokensAction,
  addLogTimeAction,
  addRefreshValidAction,
  changeIsRefreshingAction,
  addTimeDiffTokenAction,
} = tokensSlice.actions;

// SELECTOR

export const tokensAccessSelector = (state: IStoreState) => state.tokens.accessToken;
export const tokensRefreshSelector = (state: IStoreState) => state.tokens.refreshToken;
export const tokensExpireSelector = (state: IStoreState) => state.tokens.expire;
export const tokensTimeDiffSelector = (state: IStoreState) => state.tokens.timeDiff;
export const tokensLogedTimeSelector = (state: IStoreState) => state.tokens.logTime;
export const tokensRefValidSelector = (state: IStoreState) => state.tokens.refresh_valid;
export const tokensIsRefreshingSelector = (state: IStoreState) => state.tokens.isRefreshing;
export const tokensEmailIsConfirmedSelector = (state: IStoreState) => state.tokens.email_confirmed;
export const tokenResetReqSelector = (state: IStoreState) => state.tokens.email_confirmed;
export const tokensSelector = (state: IStoreState) => state.tokens;
