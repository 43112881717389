
import {call, put} from '@redux-saga/core/effects';
import {
  addAccessTokenAction,
  addRefreshTokenAction, addTimeDiffTokenAction,
} from "../../slices/tokensSlice";
import {
  IAction, IError, ISignInResponse
} from "../../../models";
import { decodeJwtSaga } from "./decodeJwtSaga";
import { errorsUiUpdateAction } from "../../slices/errorSlice";
import { countDiffTime } from "../../../helpers";

export function* writeTokensSaga(action: IAction<ISignInResponse>): Generator | Generator<any> {
  try {
    const response = action.payload;
    yield put(errorsUiUpdateAction(null));

    if (!(response as object).hasOwnProperty('errorCode')) {
      const data = (response as ISignInResponse);
      yield put(addRefreshTokenAction(data.refreshToken));
      yield put(addAccessTokenAction(data.accessToken));
      yield call(() => decodeJwtSaga(data.userClaims));
      const diffTime = yield countDiffTime(data.operationTime);
      yield put(addTimeDiffTokenAction(diffTime as number));
    }
    else {
      throw response;
    }
  }
  catch (e) {
    yield put(errorsUiUpdateAction(e as IError));
  }
}
