import {
  call, put, select
} from "@redux-saga/core/effects";
import {
  changeIsRefreshingAction, tokensRefreshSelector, writeTokensAction
} from "../../slices/tokensSlice";
import { fetchRefreshToken } from "../../../services/RefreshService";
import { errorsClear } from "../../slices/errorSlice";

export function* updateTokenSaga(): Generator {
  try {
    yield put(changeIsRefreshingAction(true));
    const refToken = yield select(tokensRefreshSelector);
    const resp = yield call(() => fetchRefreshToken(refToken as string));
    if (!(resp as object).hasOwnProperty('errorCode')) {
      yield put(writeTokensAction(resp));
      yield put(errorsClear());
    }
  }
  catch (e) {
    console.log(e);
  }
  finally {
    yield put(changeIsRefreshingAction(false));
  }
}
