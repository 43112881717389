import {environment} from "../environments/environment";

export const checkExpire = (expire: number, timeDiff: number): boolean => {
  const currentValue = new Date().valueOf();
  const updatedCurrent = new Date(currentValue + (timeDiff));
  updatedCurrent.setMinutes(updatedCurrent.getMinutes() + environment.refreshDelay);
  const modExpire = expire * 1000;

  return updatedCurrent.valueOf() >= modExpire;
};
