import {
  call, cancelled, put, select
} from "@redux-saga/core/effects";
import { uiChangeLoadingAction, uiChangeMessageAction } from "../../slices/uiSlice";
import { IError, IUserDetails } from "../../../models";
import {
  addUserDetailsNameAction, addUserDetailsSurnameAction, userDetailsSelector
} from "../../slices/userSlice";
import { errorsUiUpdateAction, errorsUserDetailsUpdateAction } from "../../slices/errorSlice";
import { fetchAddUserDetails } from "../../../services/UserService";

export function* updateUserDetailsSaga(): Generator {
  try {
    const data: IUserDetails | unknown = yield select(userDetailsSelector);
    yield put(uiChangeLoadingAction(true));
    yield put(errorsUserDetailsUpdateAction(null));
    yield put(errorsUiUpdateAction(null));

    const response = yield call(() => fetchAddUserDetails(
      {
        name: (data as IUserDetails).name,
        surname: (data as IUserDetails).surname
      }));
    if ((response as object).hasOwnProperty('errorCode') && (response as IError).errorCode !== 10) {
      cancelled();
      throw response;
    }
    else {
      yield put(addUserDetailsNameAction((response as IUserDetails).name));
      yield put(addUserDetailsSurnameAction((response as IUserDetails).surname));

      yield put(uiChangeMessageAction({
        isShow: true,
        title: 'Updated!',
        desc: `Congratulations you successfully updated your details`,
        role: 'inf'
      }));
    }
  }
  catch (e) {
    if ((e as IError).status) {
      yield put(errorsUserDetailsUpdateAction(e as IError));
    }
    else {
      yield put(errorsUiUpdateAction(e as IError));
    }
  }
  finally {
    yield put(uiChangeLoadingAction(false));
  }
}
