import {
  tokensExpireSelector,
  tokensAccessSelector,
  tokensIsRefreshingSelector,
    tokensTimeDiffSelector,
} from "../../store/slices/tokensSlice";
import { checkExpire } from "../../helpers";
import { InternalAxiosRequestConfig } from "axios";
import { IStore } from "../../models";
import { makeRefresh } from "../makeRefresh";

export const handleRefresh = async (config: InternalAxiosRequestConfig, store: IStore) => {
  const isRefreshing = tokensIsRefreshingSelector(store.getState());

  if (!(config.url as string).includes('refresh') && !(config.url as string).includes('signout')) {

    const expire = tokensExpireSelector(store.getState());
    const timeDiff = tokensTimeDiffSelector(store.getState());
    if ((expire && checkExpire(expire, timeDiff)) && !isRefreshing) {
      await makeRefresh(store);
    }
  }

  return config;
};

export const handleAccess = async (config: any, store: IStore) => {
  const isRefreshing = tokensIsRefreshingSelector(store.getState());
  if (!isRefreshing || ((config.url as string).includes('refresh') || (config.url as string).includes('signout'))) {
    const token = tokensAccessSelector(store.getState());
    config.headers['ROOT-ACCESS'] = `${token}`;

    return config;
  }
  else {
    return await new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        const isRefreshingState = tokensIsRefreshingSelector(store.getState());
        if (!isRefreshingState) {
          const token = tokensAccessSelector(store.getState());
          config.headers['ROOT-ACCESS'] = `${token}`;
          resolve(config);
          clearInterval(interval);
        }
      }, 500);
    }).then(updatedConfig => updatedConfig);
  }
};
