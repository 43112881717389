import axios from "axios";
import { environment } from "../environments/environment";
import { handleAccess, handleRefresh } from "./interceptors/reqOperations";
import { IStore } from "../models";
import { handleError } from "./error/handleError";

let store: IStore;
export const injectStoreToHttp = (_store: IStore) => {
  store = _store;
};
axios.defaults.withCredentials = true;
const $api = axios.create({
  baseURL: `${environment.apiUrl}`,
  validateStatus: function (status) {
    return status >= 200 && status < 400;
  },
  withCredentials: true,
});

// @ts-ignore
$api.interceptors.request.use(async (config) => {
  return await handleRefresh(config, store).then(conf => handleAccess(conf, store));
}, (e) => {
  return Promise.reject(e);
});

$api.interceptors.response.use(null, (error) => {
  return handleError($api, error, store).then(() => Promise.reject(error));
});

export default $api;
